<template>
  <div>
    <edit
      card-header="View Community Event"
      :disabled="true"
    />
  </div>
</template>
<script>
import edit from './edit.vue'

export default {
  name: 'ViewCommunityEvent',
  components: { edit },
}
</script>
<style lang="">

</style>
